<template>
<div id='discussionForum'>

    <div class="left">
        <div class="top">
            <h2 class='title'>Discussion Forum</h2>
            <v-btn v-if="activeTopic === true" class='topicBackButton' fab small @click="removeActiveTopic">
                <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
        </div>
        <div class="bottom" :class="{displayNone: activeTopic}" v-if="!busy.topics">
            <Topics id='Topics' @selectTopic="selectTopic" :topics="topics" :selectedTopic="selectedTopic" />
        </div>
        <div v-else>
            <v-icon x-large style='display:flex; justify-content:center; align-items:center;'>mdi-spin mdi-loading</v-icon>
        </div>
    </div>

    <div class="right" :class="{displayBlock: activeTopic}">
        <div class="top">
            <template v-if="selectedTopic">
                <h3>{{selectedTopic.title[language]}}:</h3>
                <div class="descriptionContainer">
                    <h4>{{selectedTopic.subtitle[language]}}</h4>
                </div>
            </template>
            <p v-else style="margin-top:40px; font-style:italic;">
                <v-icon>
                    mdi-arrow-bottom-left
                </v-icon>
                please, select a Topic
            </p>
            <template v-if="selectedTopic">
                <span v-if="selectedTopic.resources.length" style="font-weight:500; margin-top:8px; display:block;">Resources:</span>
                <div class="documentCardContainer">
                    <template v-for="(resource, index) in selectedTopic.resources">
                        <DocumentCard id='DocumentCard' v-if="resource.restrictedTo.length==0 || checkRole(resource.restrictedTo)" :type="'chip'" :resource="resource" :key="`discussion-resource-${index}`" />
                    </template>
                </div>
            </template>
        </div>
        <div class="bottom">
            <div v-if="selectedTopic">
                <template v-if="!busy.posts">
                    <Posts :userMap="userMap" :posts="filteredPosts" :editing="form" @edit="editPost" @cancelEdit="form=messageTemplate()" @delete="deletePost" />
                    <div class="chatContainer">
                        <v-textarea v-model="form.message" style="width: 80%; margin: 0 auto;" auto-grow dense label="Comment" outlined background-color="white">
                            <v-btn v-if="form.message!=null && form.message.trim().length>0" icon slot="append" @click="postMessage" :class="form.message.trim().length===0 ? 'displayNone' : ''">
                                <v-icon color="blue">
                                    mdi-send
                                </v-icon>
                            </v-btn>
                        </v-textarea>
                    </div>
                </template>
                <center v-else style="margin: 50px;">
                    <v-icon x-large>mdi-spin mdi-loading</v-icon>
                </center>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import Topics from '@/components/DiscussionForum/Topics'
import Posts from '@/components/DiscussionForum/Posts'
import DocumentCard from '@/components/Resources/documentCard'
import MeetingSystem from '@/components/Event/eventSystem.js'
import allowTopic from '@/mixins/allowTopic.js'

export default {
    name: "DiscussionForum",
    mixins: [allowTopic],
    created: async function() {
        this.meetingsAPI = new MeetingSystem(this)
        if (this.meetings == undefined) {
            let meetings = await this.meetingsAPI.getMeetings()
            this.$store.commit('meetings', meetings)
        }
        this.form = this.messageTemplate()
        let topics = await this.getTopics()
        if (topics && this.$route.params.topic_id !== undefined) {
            let targetTopic = this.topics.filter(topic => topic.id == this.$route.params.topic_id);
            if (targetTopic.length && this.allowTopic(targetTopic[0], this.meetingMap)) {
                this.selectTopic(targetTopic[0])
            }
        }
    },
    beforeDestroy: function(){
        for(let i = 0; i < this.wheelScrollHorizontalTracker.length; i++){
            let item = this.wheelScrollHorizontalTracker[i];
            window.removeEventListener('wheel', item);
        };
    },
    components: {
        Topics,
        Posts,
        DocumentCard
    },
    data: function() {
        return {
            queue: {
                topics: 0,
                posts: 0
            },
            meetingsAPI: null,
            activeTopic: false,
            selectedTopic: null,
            form: {},
            topics: [],
            posts: [],
            users: [],
            editPostReq: false,
            eventListenerTracker: [],
            wheelScrollHorizontalTracker: []
        }
    },
    methods: {
        messageTemplate: function() {
            return {
                state: 'published',
                message: null,
                restrictedTo: []
            }
        },
        getTopics: async function() {
            this.queue.topics++
            let response = await this.sendRequest('get', '/api/discussionforum/topics')
            if (response.status == 200) {
                this.topics = response.data.topics
                // this.users = response.data.users
                if (this.topics.length == 1) {
                    this.selectTopic(this.topics[0])
                }
            }
            this.queue.topics--
            return "topics-loaded";
        },
        getPosts: async function() {
            this.queue.posts++
            let topic_id = this.selectedTopic.id
            this.posts = []
            let response = await this.sendRequest('get', `/api/discussionforum/topics/${topic_id}`)
            if (response.status == 200) {
                this.posts = response.data.posts
                this.users = response.data.users
            }
            this.queue.posts--
        },
        selectTopic: function(topic) {
            this.selectedTopic = topic;
            this.activeTopic = true;
        },
        removeActiveTopic: function(){
            this.activeTopic = false;
            let self = this;
            let delay = setTimeout(function(){
                self.topicItem_resetHorizontalScroll();
            },200);
        },
        editPost: async function(post) {
            let data = JSON.stringify(post)
            data = JSON.parse(data)
            data.state = 'edited'
            this.form = data

            // postMessage follows this.
            // Here I open up small 1 second window where editPostReq flag is true.
            // This allows a conditional in postMessage to handle putting the scroll of the Posts container
            // either to the bottom when a new post is made, or to the same scroll position when a post is editted.
            this.editPostReq = true;
            let delay = setTimeout(function(){
                this.editPostReq = false;
            },1000);
        },
        deletePost: async function(post) {
            if (confirm('Delete post?')) {
                // Note initial scrollTop for .right (which is container of Posts)
                let right = document.querySelector('.right');
                let scrollPositionBeforeDelete = right.scrollTop;

                post.user_id = this.$store.getters.user.username
                let response = await this.sendRequest('delete', `/api/discussionforum/topics`, post)
                if (response.status == 200) {
                    await this.getPosts()
                    this.form = this.messageTemplate()

                    // after delete, set scrollTop of .right (container of Posts) to initial scrollTop
                    right.scrollTop = scrollPositionBeforeDelete;
                }
            }
        },
        postMessage: async function() {
            let data = JSON.stringify(this.form)
            data = JSON.parse(data)
            data.user_id = this.$store.getters.user.username,
            data.topic_id = this.selectedTopic.id,
            data.language = this.language
            data.new_posted = this.editPostReq === true ? 'no' : 'yes'

            let response = await this.sendRequest('post', '/api/discussionforum/topics', data)
            if (response.status == 200) {

                let right = null;
                let scrollPositionBeforeDelete = null;
                if( this.editPostReq === true){
                    right = document.querySelector('.right');
                    scrollPositionBeforeDelete = right.scrollTop;
                };

                this.form = this.messageTemplate()
                await this.getPosts()

                if( this.editPostReq === true){
                    right.scrollTop = scrollPositionBeforeDelete;
                    this.editPostReq = false;
                }
                else{
                    this.scrollToBottomOfChat();
                };
            }
        },
        checkRole: function(restrictedTo) {
            let myRoles = this.myRoles
            for (let i = 0; i < myRoles.length; i++) {
                let role = myRoles[i]
                if (restrictedTo.includes(role)) {
                    return true
                }
            }
            return false
        },
        scrollToBottomOfChat: function(){
            // Scroll to the bottom of the container of Posts when clicking selectTopic, postMessage, deletPost, editPost
            let right = document.querySelector('.right');
                right.scrollTop = right.scrollHeight;
        },
        topicItem_resetHorizontalScroll: function(){
            // If topic item has been horizontally scrolled, selecting one will reset them to default position
            let topicContents = document.querySelectorAll('.topicContent');
            for(let i = 0; i < topicContents.length; i++){
                let item = topicContents[i];
                    item.scrollLeft = 0;
            };
        }
    },
    computed: {
        busy: function() {
            return {
                topics: this.queue.topics > 0,
                posts: this.queue.posts > 0
            }
        },
        language: function() {
            return this.$store.getters.language
        },
        filteredPosts: function() {
            let posts = this.posts
            let output = posts.filter((post) => {
                return post.topic_id == this.selectedTopic.id
            })
            return output
        },
        meetings: function() {
            return this.$store.getters.meetings
        },
        meetingMap: function() {
            let output = {}
            let meetings = this.meetings
            for (let i = 0; i < meetings.length; i++) {
                let meeting = meetings[i]
                output[meeting.id] = meeting
            }

            return output
        },
        myRoles: function() {
            let username = this.$store.getters.user.username
            let meetingMap = this.meetingMap
            let output = []
            let selectedTopic = this.selectedTopic
            if (selectedTopic) {
                let events = selectedTopic.events
                for (let i = 0; i < events.length; i++) {
                    let event_id = events[i]
                    let roles = meetingMap[event_id] ? meetingMap[event_id].roles : {}
                    for (let key in roles) {
                        if (roles[key].includes(username)) {
                            output.push(key)
                        }
                    }
                }
            }

            return output
        },
        userMap: function() {
            let output = {}
            let users = this.users
            for (let i = 0; i < users.length; i++) {
                let user = users[i]
                output[user.Username] = user
            }
            return output
        }
    },
    watch: {
        selectedTopic: {
            deep: true,
            handler: async function() {
                this.posts = []
                if (this.selectedTopic) {
                    await this.getPosts()

                    // Scroll to the bottom of the container of Posts when clicking a Topic
                    this.scrollToBottomOfChat();
                    // If topic item has been horizontally scrolled, selecting one will reset them to default position
                    this.topicItem_resetHorizontalScroll();

                    // function to fire from listener
                    function wheelScrollsHorizontal(event){
                        event.preventDefault();
                        this.element.scrollLeft += event.deltaY;
                    }
                    // get element that will take listener
                    let documentCardContainer = document.querySelector(`.documentCardContainer`);
                    // pass element reference into function to fire from listener
                    wheelScrollsHorizontal = wheelScrollsHorizontal.bind({element: documentCardContainer});
                    // only add listener if the selectedTopic id hasn't been tracked yet
                    if( this.eventListenerTracker.includes(this.selectedTopic.id) === false){
                        documentCardContainer.addEventListener('wheel', wheelScrollsHorizontal);
                        // push selectedTopic id to track
                        this.eventListenerTracker.push(this.selectedTopic.id);
                        // push fire to function from listener to data. Will be removed beforeDestroy.
                        this.wheelScrollHorizontalTracker.push(wheelScrollsHorizontal);
                    }
                }
            }
        },
        queue: {
            deep: true,
            handler: function() {
                if (this.queue.topics < 0) {
                    this.queue.topics = 0
                }
                if (this.queue.posts < 0) {
                    this.queue.posts = 0
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

#discussionForum {
    display: flex;
    margin: 0 auto;
    text-align: left;
    width: 100%;
    max-width: 1200px;
    height: 86vh;

    @media(max-width: 767px){ // sm < 768
        flex-flow: column;
        height: 80vh;
    }

    > .left {
        width: 30%;
        padding: 0 16px;

        @media(max-width: 767px){ // sm < 768
            width: 100%;
        }

        > .top {
            height: fit-content;
            display: flex;
            position: relative;

            .title {
                margin: 24px 0 8px;
            }

            .v-btn {
                position: absolute;
                right: 16px;
                top: 10px;
                display: none;

                @media(max-width: 767px){ // sm < 768
                    display: block;
                }
            }
        }

        > .bottom {
            height: 74vh;
            overflow: scroll;
            &::-webkit-scrollbar {
                display: none;
            }

            @media(max-width: 767px){ // sm < 768
                &.displayBlock {
                    display: block;
                }
                &.displayNone {
                    display: none;
                }
            }
            @media(max-width: 575px){ // xs < 576
                height: 72vh;
            }
        }
    }

    > .right {
        width: 70%;
        padding: 0 16px;
        border-left: 1px solid lightgrey;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }

        @media(max-width: 767px){ // sm < 768
            width: 100%;
            display: none;

            &.displayBlock {
                display: block;
            }
            &.displayNone {
                display: none;
            }
        }

        > .top {
            position: sticky;
            top: 0;
            z-index: 1;
            padding: 16px 0 15px 0;
            height: fit-content;
            background-color: white;
            border-bottom: 1px solid lightgrey;

            @media(max-width: 767px){ // sm < 768
                padding: 0 16px 8px;
            }

            h3 {
                font-size: 22px;

                @media(max-width: 767px){ // sm < 768
                    font-size: 20px;
                }
                @media(max-width: 575px){ // xs < 576
                    font-size: 18px;
                }
            }

            .descriptionContainer {
                max-height: 80px;
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    display: none;
                }

                @media(max-width: 767px){ // sm < 768
                    max-height: 64px;
                }

                h4 {
                    font-style: italic;
                    font-weight: 500;
                    margin-top: 8px;
                    padding: 0 16px 0 0;

                    @media(max-width: 767px){ // sm < 768
                        font-size: 14px;
                    }
                    @media(max-width: 575px){ // xs < 576
                        font-size: 12px;
                    }
                }
            }

            .documentCardContainer {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    display: none;
                }

                #DocumentCard {
                    margin: 4px 0px 12px 4px;
                    display: block;
                }
            }
        }

        > .bottom {

            .chatContainer {
                position: sticky;
                bottom: 0;
                padding-top: 16px;
                background-color: white;

                .displayNone {
                    display: none;
                }

                .v-textarea {

                    @media(max-width: 575px){ // xs < 576
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
